<template>
  <NuxtLoadingIndicator color="#0068fa" />
  <NuxtPage />
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
useHead({
  script: config.public.injectScripts
    ? [
        {
          innerHTML: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5TS4LJZ');
      `,
          defer: true,
          async: true,
        },
        {
          src: '//js.hs-scripts.com/5731513.js',
          defer: true,
          async: true,
        },
      ]
    : [],
})
</script>
